import React from "react";
import Toastr from "toastr";
import { toAbsoluteUrl } from "../../../_helpers";
import {
  READY_TO_SHIP,
  UPDATE_SINGLE_JOB_STATUS,
} from "../../../../actions/jobs";

var count = 0;

export default function FileUpload(props) {
  const [file, setFile] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [fileUpload, setFileUpload] = React.useState(null);
  const [isLoading, setisLoading] = React.useState(false);

  const onChangeFile = (e) => {
    if (e.target.files.length > 3) {
      Toastr.warning("You can select only 3 images");
    } else {
      setFile(e.target.files);
    }

    let __files = e.target.files;
    console.log("Blob", __files);
    __files.forEach((key, i) => {
      document.getElementById(`img${i + 1}`).src = "";
      document.getElementById(`img${i + 1}`).src = window.URL.createObjectURL(
        key
      );
    });
  };

  const uploadFile = () => {
    if (file) {
      file.forEach((key, i) => {
        updateFiles(key);
      });
    } else {
      Toastr.warning("Please Select Photos to upload");
    }
  };

  const updateFiles = (key) => {
    const data = new FormData();
    data.append(`image`, key, key.name);
    setFileUpload(key);
    setisLoading(true);

    const callback = (response) => {
      if (response) {
        count++;
        setFileUpload(false);
        setProgress(0);
        Toastr.success(`${key.name} uploaded successfully.`);
        if (count === file.length) {
          updateJobStatus();
        }
      }
    };

    const onError = (error) => {
      if (error.response) {
        if (error.response.data) {
          return Toastr.error(`${error.response.data.message}`);
        }
      }
    };

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setProgress(percent);
        }
      },
    };

    READY_TO_SHIP(props.id, data, options, callback, onError);
  };

  const updateJobStatus = () => {
    const query = {
      fulfillment_status: "ready-to-ship",
    };

    const callback = (response) => {
      if (response.fulfillment_status) {
        setisLoading(false);
        setTimeout(() => {
          props.toggle(false);
          props.status("ready-to-ship");

          return Toastr.success(`Order status updated successfully.`);
        }, 1000);
      }
    };
    const onError = (error) => {
      if (error.response) {
        if (error.response.data) {
          props.toggle(false);
          return Toastr.error(`${error.response.data.message}`);
        }
      }
    };

    UPDATE_SINGLE_JOB_STATUS(props.id, query, callback, onError);
  };

  function openFileUpload() {
    var browseField = document.getElementById("file-input");
    browseField.click();
  }

  return (
    <>
      {!isLoading ? (
        <div className="fileUpload">
          <div className="upload-box">
            <div className="upload-box__header">
              <h5>Upload photos of the finished work</h5>
            </div>
            <div className="upload-box-container">
              <div className="drop-area">
                <br />
                <br />
                <br />
                <input
                  id="file-input"
                  type="file"
                  name="file"
                  onChange={(e) => onChangeFile(e)}
                  multiple
                  accept="image/jpg, image/jpeg, image/png"
                />
                {/* file */}
                <img
                  className="h-20 align-self-top"
                  src={toAbsoluteUrl("/media/svg/icons/file.svg")}
                  alt={"file"}
                />
                {/*  */}
                <p className="drop-label">
                  Drag and drop to upload or <br /> click here to select a file
                  from your system
                </p>
              </div>
            </div>

            <div className="upload-box__footer">
              <h5>Upload a maximum of 3 photos</h5>

              <div className="flex flex-col lg:flex-row ">
                <div
                  className="image-box flex flex-col content-center place-content-center justify-center"
                  onClick={openFileUpload}
                >
                  <img id="img1" src="#" className="mt-5" />
                  {!file ? <span className="f-1/5"> Add Image</span> : ""}
                </div>

                <div
                  className="image-box flex flex-col content-center place-content-center justify-center"
                  onClick={openFileUpload}
                >
                  {/* add file */}
                  <img id="img2" src="#" className="mt-5" />
                  {!file ? <span className="f-1/5"> Add Image</span> : ""}
                </div>

                <div
                  className="image-box flex flex-col content-center place-content-center justify-center"
                  onClick={openFileUpload}
                >
                  {/* add file */}
                  <img id="img3" src="#" className="mt-5" />
                  {!file ? <span className="f-1/5"> Add Image</span> : ""}
                </div>
                {/*  */}
              </div>
            </div>
          </div>

          <div className="flex flex-row w-100 justify-end  py-3 mt-5">
            <button
              className="bg-green-500 hover:bg-green-500 text-white font-bold py-3 px-4 rounded"
              onClick={() => uploadFile()}
              disabled={isLoading}
            >
              Save Job Status
            </button>
            <button
              className="bg-gray-100 hover:bg-gray-100 text-gray font-bold py-3 px-4 mx-5 rounded"
              onClick={() => props.toggle(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-5 mb-5">
          <h6 className="text-gray-100">
            Please wait while file uploads.... <br />
            <div class="lds-roller mx-10 mt-10">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </h6>

          {/* {file
            ? Object.values(file).map((key, i) => {
                return fileUpload?.name === key.name ? (
                  <div className="relative pt-1 mt-10 mb-3">
                    <div className="flex mb-2 items-center justify-between">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                          {key.name}
                        </span>
                      </div>
                      <div className="text-right">
                        <span className="text-xs font-semibold inline-block text-green-600">
                          {progress}%
                        </span>
                      </div>
                    </div>
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                      <div
                        style={{
                          width: progress + "%",
                        }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
                      ></div>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })
            : ""} */}
        </div>
      )}
    </>
  );
}
