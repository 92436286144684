import React from "react";
import JobsTableWidget from "../widgets/base-tables/JobsTableWidget";

export function JobsComponent(props) {
  return (
    <>
      <JobsTableWidget jobPage={true} />
    </>
  );
}
