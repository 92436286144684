/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { NavLink, Link, useLocation } from "react-router-dom";
// import SVG from "react-inlinesvg";

import { connect } from "react-redux";

import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function SubHeader({ user }) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-1 ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} flex items-center justify-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="flex items-center flex-wrap mr-1">
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          {/* begin::Heading */}
          <div className="flex align-items-baseline mr-5">
            {/* begin::Title */}
            <h2 className="subheader-title text-dark font-medium my-1 mr-3">
              {/* {subheader.title} */}
            </h2>
            {/* end::Title */}
          </div>
          {/* end::Heading */}
        </div>
        {/* Info */}

        {/* Toolbar */}
        <div className="flex items-center">
          <ul className="topmenu">
            <li>
              <NavLink to="#" className="topmenu-item">
                <div>
                  {`${user.first_name} ${user.last_name}`}
                  <span> {`${user.print_partners[0].name}`} </span>
                </div>

                <span className="topmenu-item-badge hide__mobile">
                  {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
                </span>
              </NavLink>
            </li>
            <li>
              <Link to="/logout" className="topmenu-item mx-5 py-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M14.0009 7C14.0009 7.55228 14.4484 8 15.0005 8C15.5525 8 16 7.55228 16 7V6C16 3.79086 14.2099 2 12.0018 2H6.00522C3.79704 2 2.00696 3.79086 2.00696 6L2.00696 18C2.00696 20.2091 3.79704 22 6.00522 22H12.0087C14.2169 22 16.007 20.2091 16.007 18V17C16.007 16.4477 15.5594 16 15.0074 16C14.4553 16 14.0078 16.4477 14.0078 17V18C14.0078 19.1046 13.1128 20 12.0087 20H6.00522C4.90113 20 4.00609 19.1046 4.00609 18L4.00609 6C4.00609 4.89543 4.90113 4 6.00522 4H12.0018C13.1058 4 14.0009 4.89543 14.0009 6V7Z"
                    fill="#506683"
                  />
                  <path
                    opacity="0.3"
                    d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11L9 11C8.44771 11 8 11.4477 8 12C8 12.5523 8.44771 13 9 13L19 13Z"
                    fill="#506683"
                  />
                  <path
                    d="M17.2929 9.70711C16.9024 9.31658 16.9024 8.68342 17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071C16.9024 15.3166 16.9024 14.6834 17.2929 14.2929L19.5858 12L17.2929 9.70711Z"
                    fill="#506683"
                  />
                </svg>

                <span className="topmenu-item-text">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = function(state) {
  return {
    user: state.auth.user,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     dispatch,
//   };
// };

export default connect(mapStateToProps, null)(SubHeader);
