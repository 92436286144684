import React from "react";
import { RevenueWidget, StatsChartWidget } from "../widgets";
import JobsTableWidget from "../widgets/base-tables/JobsTableWidget";

export function DashboardComponent() {
  const incomeSeries = {
    name: "Net Profit",
    data: [0, 0, 0, 0, 0, 0, 0],
  };

  const jobsSeries = {
    name: "Jobs",
    data: [0, 0, 0, 0, 0, 0, 0],
  };
  return (
    <div className="container">
      {/* begin::Dashboard */}

      {/* begin::Row */}
      <div className="flex flex-col lg:flex-row mt-10">
        <div className="w-100 lg:w-1/3 xl:w-1/3 px-3 mt-5">
          <RevenueWidget className="gutter-b card-stretch" />
        </div>
        <div className="w-100 lg:w-1/3 xl:w-1/3 px-3 mt-5">
          <StatsChartWidget
            symbolShape="circle"
            title="Weekly Income"
            amount="0"
            seriesObj={incomeSeries}
            valType="money"
            widgetID="income_chart"
            percentIncrease={0}
            baseColor="danger"
          />
        </div>
        <div className="w-100 lg:w-1/3 xl:w-1/3 px-3 mt-5">
          <StatsChartWidget
            className="gutter-b"
            title="Completed Jobs"
            seriesObj={jobsSeries}
            widgetID="jobs_chart"
            amount="0"
            percentIncrease={0}
            baseColor="info"
          />
        </div>
      </div>
      {/* end::Row */}

      <JobsTableWidget dashboard={true} />

      {/* end::Dashboard */}
    </div>
  );
}
