import React from 'react';
import { SingleJobComponent } from '../../_metronic/_partials';

export function SingleJobView(props) {
  return (
    <>
      <SingleJobComponent props={props} />
    </>
  );
}
