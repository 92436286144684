import React from "react";
import { SearchPageWidget } from "../widgets";

export function SearchComponent() {
  return (
    <>
      {/* begin::Dashboard */}
      <SearchPageWidget />
    </>
  );
}
