/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toS3Url, formatDate } from "../../../_helpers";
import Toastr from "toastr";
import swal from "sweetalert";
import numeral from "numeral";

import { Link, useHistory } from "react-router-dom";
import {
  GET_ASSIGNED_JOBS,
  GET_ONGOING_JOBS,
  GET_COMPLETED_JOBS,
  ACCEPT_A_JOB,
  DECLINE_A_JOB,
} from "../../../../actions/jobs";
import { connect } from "react-redux";

export function JobsTableWidget({ className, dashboard, jobPage, user }) {
  useEffect(() => {
    assignedJobs();
  }, [dashboard, jobPage]);

  const history = useHistory();

  const tabs = {
    tab1: "assigned",
    tab2: "ongoing",
    tab3: "completed",
  };
  const [activeTab, setActiveTab] = useState(tabs.tab1);
  const [loading, setLoading] = React.useState(false);

  const [jobs, setJobs] = useState(null);

  const switchTab = (tab_id) => {
    switch (tab_id) {
      case "assigned":
        setJobs(null);
        setActiveTab(tabs.tab1);
        assignedJobs();
        break;
      case "ongoing":
        setJobs(null);
        setActiveTab(tabs.tab2);
        onGoingJobs();
        break;
      case "completed":
        setJobs(null);
        setActiveTab(tabs.tab3);
        completedJobs();
        break;
      default:
        setActiveTab(tabs.tab1);
        assignedJobs();
        break;
    }
  };

  const assignedJobs = () => {
    setJobs([]);
    const callback = (response) => {
      if (response.data.length > 0) {
        setJobs(response.data);
      } else {
        setJobs(null);
      }
    };
    const onError = (error) => {
      console.log(error);
    };
    GET_ASSIGNED_JOBS(user.print_partners[0].id, callback, onError);
  };

  const onGoingJobs = () => {
    setJobs([]);
    const callback = (response) => {
      if (response.data.length > 0) {
        setJobs(response.data);
      } else {
        setJobs(null);
      }
    };
    const onError = (error) => {
      console.log(error);
    };

    GET_ONGOING_JOBS(user.print_partners[0].id, callback, onError);
  };

  const completedJobs = () => {
    setJobs([]);
    const callback = (response) => {
      if (response.data.length > 0) {
        setJobs(response.data);
      } else {
        setJobs(null);
      }
    };
    const onError = (error) => {
      console.log(error);
    };

    GET_COMPLETED_JOBS(user.print_partners[0].id, callback, onError);
  };

  const acceptJob = (job_id) => {
    setLoading(true);

    const callback = (response) => {
      if (response) {
        Toastr.success(`You have accepted this job.`);
        history.push(`/jobs/${job_id}`);
      }
    };
    const onError = (error) => {
      console.log(error);
    };

    ACCEPT_A_JOB(job_id, callback, onError);
  };

  const declineJob = (job_id) => {
    setLoading(true);

    swal("Are you sure you want to reject job?", {
      buttons: {
        cancel: "Cancel",
        yes: {
          text: "Yes delete",
          value: "yes",
        },
      },
      icon: "warning",
    }).then((value) => {
      switch (value) {
        case "yes":
          const callback = (response) => {
            if (response.declined) {
              setJobs([]);
              assignedJobs();
              setLoading(false);

              Toastr.error(`You have declined this job.`);
            }
          };
          const onError = (error) => {
            console.log(error);
          };

          DECLINE_A_JOB(job_id, callback, onError);

          break;

        default:
          swal({
            title: "Cancelled",
            text: "Job was not declined",
            icon: "error",
          });
      }
    });
  };

  return (
    <>
      <div className={`card card-custom border-0  gutter-b rounded-none`}>
        {jobPage ? (
          <div className="card-header pt-5">
            <h3 className="card-title align-items-start flex-col">
              <span className="card-label font-weight-bolder text-dark">
                Jobs
              </span>
              <span className="text-grey-400 mt-3 font-medium font-size-sm">
                Assigned Jobs to <span className="font-bold">Lorem</span>
              </span>
            </h3>
            <div className="card-toolbar">
              <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                <li className="nav-item">
                  <div
                    className={`nav-link py-2 px-4 cursor ${activeTab ===
                      tabs.tab1 && "bg-grey-custom text-white"}`}
                    onClick={() => switchTab(tabs.tab1)}
                  >
                    New Jobs
                  </div>
                </li>
                <li className="nav-item">
                  <div
                    className={`nav-link py-2 px-4 cursor ${activeTab ===
                      tabs.tab2 && "bg-grey-custom text-white"}`}
                    onClick={() => switchTab(tabs.tab2)}
                  >
                    Ongoing Jobs
                  </div>
                </li>
                <li className="nav-item">
                  <div
                    className={`nav-link py-2 px-4 cursor ${activeTab ===
                      tabs.tab3 && "bg-grey-custom text-white"}`}
                    onClick={() => switchTab(tabs.tab3)}
                  >
                    Completed Jobs
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
      </div>

      <div className={jobPage ? `container` : ""}>
        <div className={`card card-custom ${className}`}>
          {dashboard ? (
            <div className="card-header pt-5">
              <h3 className="card-title align-items-start flex-col">
                <span className="card-label font-weight-bolder text-dark">
                  Jobs
                </span>
                <span className="text-grey-400 mt-3 font-medium font-size-sm">
                  Assigned Jobs to <span className="font-bold">Lorem</span>
                </span>
              </h3>
              <div className="card-toolbar">
                <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                  <li className="nav-item">
                    <div
                      className={`nav-link py-2 px-4 cursor ${activeTab ===
                        tabs.tab1 && "bg-grey-custom text-white"}`}
                      onClick={() => switchTab(tabs.tab1)}
                    >
                      New Jobs
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link py-2 px-4 cursor ${activeTab ===
                        tabs.tab2 && "bg-grey-custom text-white"}`}
                      onClick={() => switchTab(tabs.tab2)}
                    >
                      Ongoing Jobs
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link py-2 px-4 cursor ${activeTab ===
                        tabs.tab3 && "bg-grey-custom text-white"}`}
                      onClick={() => switchTab(tabs.tab3)}
                    >
                      Completed Jobs
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}

          <div className="card-body pt-2 pb-0">
            <div className="job__card__body">
              {/*  */}
              {jobs !== null ? (
                jobs.map((item, i) => (
                  <div
                    className="job__card__content bodered-1-rounded "
                    key={i + 1}
                  >
                    <div className="d-flex flex-col lg:flex-row ">
                      <div className="lg:mx-5">
                        <img
                          className="h-20 align-self-end"
                          width="100"
                          height="100"
                          src={toS3Url(
                            `${item.order_item.product.thumbnail_path}`
                          )}
                          alt={item.order_item.product.name}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <Link
                          to={item.accepted ? `/jobs/${item.id}` : "#"}
                          className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          {item.order_item.product.name}
                        </Link>
                        <div className="d-flex flew-col lg:flex-row">
                          <span className="text-grey-700 font-light block mt-2">
                            {item.order_item.quantity}{" "}
                            {item.order_item.quantity > 1 ? "pieces" : "piece"}
                          </span>
                          {item.is_reprint_for ? (
                            <div className="reprint__tag">Reprint</div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-column">
                      <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg ">
                        &#8358; {numeral(item.amount).format("0,0")}
                      </span>
                      {item.accepted && (
                        <span className="text-red font-light block mt-2">
                          Deadline: {formatDate(item.accepted)}
                        </span>
                      )}
                    </div>

                    {activeTab === tabs.tab1 ? (
                      <div className="d-flex flex-row justify-content-between">
                        <button
                          className={`bg-green-custom px-5 py-2 text-white rounded lg:mx-5 _h-40 ${
                            loading ? "bg-gray-200 text-dark" : ""
                          } `}
                          onClick={() => acceptJob(item.id)}
                          disabled={loading}
                        >
                          Start Job
                          {loading && (
                            <span className="mr-1 spinner spinner-white"></span>
                          )}
                        </button>

                        {!item.is_reprint_for ? (
                          <button
                            className={`bg-red-custom px-5 py-2 text-white rounded lg:mx-5 _h-40 ${
                              loading ? "bg-gray-200 text-dark" : ""
                            } `}
                            onClick={() => declineJob(item.id)}
                            disabled={loading}
                          >
                            Reject Job
                            {loading && (
                              <span className="mr-1 spinner spinner-white"></span>
                            )}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="flex flex-col lg:flex-row">
                        <div className="d-flex flex-column">
                          <span className="text-grey-700 font-bold block mt-2">
                            Job Status:
                          </span>
                          <span className="text-grey-700 font-light block mt-2">
                            {item.fulfillment_status
                              ? item.fulfillment_status.toUpperCase()
                              : ""}
                          </span>
                        </div>

                        <Link to={item.accepted ? `/jobs/${item.id}` : "#"}>
                          <button className="bg-green-custom px-4 py-2 text-white rounded lg:mx-5 _h-40">
                            View Job
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <>
                  <div className="flex content-center justify-items-center place-content-center">
                    <div className="flex  bg-red-500 text-white w-50 p-5 font-bold">
                      You have no {activeTab} jobs.
                    </div>
                  </div>
                </>
              )}

              {/* <div className="flex p-20 content-center justify-items-center place-content-center">
                <button className="bg-gray-700 hover:bg-gray-700 text-white font-bold py-3 px-4 rounded">
                  Load More
                </button>
              </div> */}
              {/*  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = function(state) {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(JobsTableWidget);
