import axios from "axios";

export const core = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_URL,
  timeout: 150000,
  transformResponse: axios.defaults.transformResponse.concat((data) => {
    if (data.message === "Expired token") {
      console.log("expired");
      // return false;
    }
    return data;
  }),

  validateStatus: function(status) {
    return status >= 200 && status < 400;
  },
});

export const useIntercepotrs = () => {
  core.interceptors.request.use(
    function(config) {
      config.headers = {
        ...config.headers,
      };

      let user = JSON.parse(localStorage.getItem("persist:v706-demo3-auth"));
      let token = user.authToken;
      if (token) {
        token.replace(/['"]+/g, "");
        config.headers.Authorization = `Bearer ${token.replace(/['"]+/g, "")}`;
      }

      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
};
export const _intercepotrs = () => {
  core.interceptors.request.use(
    function(config) {
      config.headers = {
        ...config.headers,
      };

      let user = JSON.parse(localStorage.getItem("persist:v706-demo3-auth"));
      let token = user.authToken;
      if (token) {
        token.replace(/['"]+/g, "");
        config.headers.Authorization = `Bearer ${token.replace(/['"]+/g, "")}`;
      }

      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
};

export const useToken = (token) => {
  core.interceptors.request.use(
    function(config) {
      config.headers = {
        ...config.headers,
      };

      config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
};

core.interceptors.response.use(
  function(response) {
    if (response.status === 401) {
      console.log(response);
      return response;
    }
    if (response.status === 404) {
      console.log(response);
      return response;
    }
    if (response.status === 500) {
      console.log(response);
      return response;
    }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);
