import { core, useToken } from "../index";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const USER_REQUESTED = "USER_REQUESTED";
export const USER_LOADED = "USER_LOADED";

export const actions = {
  login: (authToken) => ({ type: LOGIN, payload: { authToken } }),
  register: (authToken) => ({
    type: REGISTER,
    payload: { authToken },
  }),
  logout: () => ({ type: LOGOUT }),
  requestUser: (user) => ({ type: USER_REQUESTED, payload: { user } }),
  fulfillUser: (user) => ({ type: USER_LOADED, payload: { user } }),
};

export async function USER_LOGIN(data, callback, onError) {
  try {
    let user = await core.post(`login.json`, data);
    if (user.data) {
      callback && callback(user.data);
    } else {
      throw user;
    }

    return user;
  } catch (err) {
    onError && onError(err);
    return false;
  }
}

export async function FORGET_PASSWORD(email, callback, onError) {
  try {
    let user = await core.post(`customers/${email}/reset-password.json`);
    if (user.data) {
      callback && callback(user.data);
    } else {
      throw user;
    }

    return user;
  } catch (err) {
    onError && onError(err);
    return false;
  }
}

export async function GET_USER_SESSION(token, callback, onError) {
  try {
    useToken(token);
    let user = await core.get(`session.json?include=print_partners`);
    if (user.data) {
      callback && callback(user.data);
    } else {
      throw user;
    }

    return user;
  } catch (err) {
    onError && onError(err);
    return false;
  }
}
