import React from "react";
import { RevenuePageWidget } from "../widgets";

export function RevenueComponent() {
  return (
    <>
      {/* begin::Dashboard */}
      <RevenuePageWidget />
    </>
  );
}
