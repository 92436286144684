import React, { useLayoutEffect } from "react";
import { KTUtil } from "../../_assets/js/components/util";
import KTLayoutAside from "../../_assets/js/layout/base/aside";
import KTLayoutHeaderMobile from "../../_assets/js/layout/base/header-mobile";
import KTLayoutAsideToggle from "../../_assets/js/layout/base/aside-toggle";
import KTLayoutAsideMenu from "../../_assets/js/layout/base/aside-menu";
import KTLayoutContent from "../../_assets/js/layout/base/content";
import KTLayoutFooter from "../../_assets/js/layout/base/footer";

export function LayoutInit() {
  useLayoutEffect(() => {
    // Initialization
    KTUtil.ready(function() {
      ////////////////////////////////////////////////////
      // Layout Base Partials(mandatory for core layout)//
      ////////////////////////////////////////////////////
      KTLayoutHeaderMobile.init("kt_header_mobile");

      // Init Aside
      KTLayoutAside.init("kt_aside");

      // Init Content
      KTLayoutContent.init("kt_content");

      KTLayoutAsideToggle.init("kt_aside_toggle");

      // Init Aside Menu
      KTLayoutAsideMenu.init("kt_aside_menu");

      // Init Footer
      KTLayoutFooter.init("kt_footer");
    });
  }, []);
  return <></>;
}
