import { FETCH_NAV_ITEMS, FETCH_CATEGORIES, SESSION_RETRIEVED } from '../actions/NavHeader';


const defaultState = {
  currentUser: {
    user: null,
  },
  navBarInfo: [],
  storeInfo: { userHasStore: false, storeSlug: null },
  categories: [],
  numberOfCartItems: 0,
  showMobileMenu: false,
  showUserMobileMenu: false,
};

export default (state = defaultState, action) => {
  const { stores } = (action.user || { stores: [] });


  switch (action.type) {
    case FETCH_NAV_ITEMS:
      return Object.assign({}, state,
        { numberOfCartItems: action.payload.length });
    case FETCH_CATEGORIES:
      return Object.assign({}, state, { categories: action.payload });
    case SESSION_RETRIEVED:
      return {
        ...state,
        currentUser: {
          user: action.user,
        },
        storeInfo: {
          ...state.storeInfo,
          userHasStore: stores.length,
          storeSlug: stores.length ? stores[0].slug : null,
        },
        numberOfCartItems: action.cart_size,
      };
    default:
      return state;
  }
};
