// import api from '../utils/api';

export const FETCH_NAV_ITEMS = "FETCH_NAV_ITEMS";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const SESSION_RETRIEVED = "SESSION_RETRIEVED";

export const getLoggedInUser = () => (dispatch) => {
  let user = localStorage.getItem("user");

  console.log(user);
  return false;
  // return (
  //   api.core.get(
  //     'categories.json?vendor_id=2&ParentID=<null>&include=sub_categories',
  //   )
  //     .then((response) => {
  //       dispatch({
  //         type: FETCH_CATEGORIES,
  //         payload: response.data.data
  //           .filter(category => category.sub_categories
  //             .filter(subCategory => subCategory.product_count > 0).length > 0),
  //       });
  //       return response.data;
  //     }).catch(e => console.log(e))
  // );
};
