import { core, useIntercepotrs } from "../index";
import Toast from "toastr";

export function JWT_EXPIRED(err) {
  // if (err.message === "Expired token") {
  Toast.error("Token Expired");
  localStorage.removeItem("persist:v706-demo3-auth");
  return (window.location = "/");
  // }
}

export async function GET_JOBS(query, callback, onError) {
  try {
    useIntercepotrs();
    let jobs = await core.get(`/print-partners/1/jobs.json?${query}`);
    if (jobs.data) {
      callback && callback(jobs.data);
    } else {
      throw jobs;
    }

    return jobs;
  } catch (err) {
    onError && onError(err);

    return JWT_EXPIRED(err);
  }
}

export async function GET_ASSIGNED_JOBS(param, callback, onError) {
  try {
    useIntercepotrs();
    let jobs = await core.get(
      `/print-partners/${param}/jobs/assigned.json?include=order_item.product&sort[created]=desc`
    );
    if (jobs.data) {
      callback && callback(jobs.data);
    } else {
      throw jobs;
    }

    return jobs;
  } catch (err) {
    onError && onError(err);

    return JWT_EXPIRED(err);
  }
}

export async function GET_ONGOING_JOBS(param, callback, onError) {
  try {
    useIntercepotrs();
    let jobs = await core.get(
      `/print-partners/${param}/jobs/ongoing.json?include=order_item.product&sort[created]=desc`
    );
    if (jobs.data) {
      callback && callback(jobs.data);
    } else {
      throw jobs;
    }

    return jobs;
  } catch (err) {
    onError && onError(err);

    return JWT_EXPIRED(err);
  }
}

export async function GET_COMPLETED_JOBS(param, callback, onError) {
  try {
    useIntercepotrs();
    let jobs = await core.get(
      `/print-partners/${param}/jobs/completed.json?include=order_item.product&sort[created]=desc`
    );
    if (jobs.data) {
      callback && callback(jobs.data);
    } else {
      throw jobs;
    }

    return jobs;
  } catch (err) {
    onError && onError(err);

    return JWT_EXPIRED(err);
  }
}

export async function GET_SINGLE_JOB_POST(param, query, callback, onError) {
  try {
    useIntercepotrs();
    let job = await core.get(`/print-jobs/${param}.json?${query}`);
    if (job.data) {
      callback && callback(job.data);
    } else {
      throw job;
    }

    return job;
  } catch (err) {
    onError && onError(err);

    return JWT_EXPIRED(err);
  }
}

export async function READY_TO_SHIP(param, data, options, callback, onError) {
  try {
    useIntercepotrs();
    let job = await core.post(
      `/print-jobs/${param}/images.json`,
      data,
      options
    );
    if (job.data) {
      callback && callback(job.data);
    } else {
      throw job;
    }

    return job;
  } catch (err) {
    onError && onError(err);

    return JWT_EXPIRED(err);
  }
}

export async function ACCEPT_A_JOB(param, callback, onError) {
  try {
    useIntercepotrs();
    let job = await core.post(`/print-jobs/${param}/accept.json`, {});
    console.log(job);
    if (job.data) {
      callback && callback(job.data);
    } else {
      throw job;
    }

    return job;
  } catch (err) {
    onError && onError(err);

    return JWT_EXPIRED(err);
  }
}

export async function DECLINE_A_JOB(param, callback, onError) {
  try {
    let job = await core.post(`/print-jobs/${param}/decline.json`, {});
    if (job.data) {
      callback && callback(job.data);
    } else {
      throw job;
    }

    return job;
  } catch (err) {
    onError && onError(err);

    return JWT_EXPIRED(err);
  }
}

export async function UPDATE_SINGLE_JOB_STATUS(
  param,
  query,
  callback,
  onError
) {
  try {
    useIntercepotrs();
    let job = await core.put(`/print-jobs/${param}.json`, query);
    if (job.data) {
      callback && callback(job.data);
    } else {
      throw job;
    }

    return job;
  } catch (err) {
    onError && onError(err);

    return JWT_EXPIRED(err);
  }
}
