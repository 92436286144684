import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { actions } from "../../../../actions/Auth";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FORGET_PASSWORD } from "../../../../actions/Auth/index";
import Toastr from "toastr";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      const callback = (response) => {
        if (response) {
          Toastr.success(
            `A Reset Link has been sent to your email - ${values.email}.`
          );
          setLoading(false);

          props.history.push("/auth");
        }
      };
      const onError = (error) => {
        if (error) {
          setLoading(false);
          Toastr.error(`Customer ${values.email} does not exist.`);
        }
      };

      FORGET_PASSWORD(values.email, callback, onError);
      console.log(values);
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="loginScreen">
          <div className="loginScreen__body">
            <div className="login__card ">
              <Link to="/" className="flex-col-auto">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/svg/logos/printivo.svg")}
                />
              </Link>

              <img
                alt="Logo"
                className="mt-10"
                src={toAbsoluteUrl("/media/svg/logos/login-image.png")}
              />
            </div>
            <div className="login__card">
              <div
                className="login-form login-forgot"
                style={{ display: "block" }}
              >
                <div className="text-center mb-10 mb-lg-20">
                  <h3 className="font-size-h1">Forgotten Password ?</h3>
                  <div className="text-grey-400 font-medium">
                    Enter your email to reset your password
                  </div>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                >
                  {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                      <div className="alert-text font-medium">
                        {formik.status}
                      </div>
                    </div>
                  )}
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      type="email"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "email"
                      )}`}
                      name="email"
                      {...formik.getFieldProps("email")}
                      placeholder="Enter your email to reset your password"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group flex flex-wrap flex-center">
                    <button
                      id="kt_login_forgot_submit"
                      type="submit"
                      className="btn btn-primary font-medium px-9 py-4 my-3 mx-4"
                      disabled={loading}
                    >
                      <span>Submit</span>
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </button>
                    <Link to="/auth">
                      <button
                        type="button"
                        id="kt_login_forgot_cancel"
                        className="btn btn-light-primary font-medium px-9 py-4 my-3 mx-4"
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, actions)(ForgotPassword));
