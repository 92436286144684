/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  toS3Url,
  toAbsoluteUrl,
  isEmpty,
  toCCUrl,
  formatDate,
} from "../../../_helpers";
import {
  GET_SINGLE_JOB_POST,
  UPDATE_SINGLE_JOB_STATUS,
} from "../../../../actions/jobs";
import FileUpload from "./FileUpload";
import Toastr from "toastr";
import numeral from "numeral";

export function SingleJobWidget(props) {
  const history = useHistory();

  const [id, setId] = useState(null);
  const [job, setJob] = useState(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [propertyKeys, setPropertyKeys] = useState(null);
  const [orderItem, setorderItem] = useState(null);
  const [files, setFiles] = useState(null);
  const [OrderStatus, setOrderStatus] = useState("");
  const [loading, setLoading] = React.useState(false);

  // const [progress, setProgress] = useState(0);

  useEffect(() => {
    let param_id = props.props.match.params.id;
    if (param_id) {
      return getJobDetail(param_id);
    }
  }, [props]);

  const getJobDetail = (id) => {
    setId(id);
    const callback = (response) => {
      if (response.accepted === null) {
        return history.goBack();
      }
      // console.log(typeof(response.is_reprint_for));
      setJob(response);
      setorderItem(response.order_item);
      setOrderStatus(response.fulfillment_status);
      // console.log(response.fulfillment_status);
      setPropertyKeys(Object.keys(response.order_item.add_ons));
      if (response.order_item.files) {
        setFiles(Object.entries(response.order_item.files));
      }
    };
    const onError = (error) => {
      console.log(error);
    };

    GET_SINGLE_JOB_POST(id, "include=order_item.product", callback, onError);
  };

  const toggleUpdateStatus = () => {
    setToggleUpdate(!toggleUpdate);
  };
  const OrderUpdateStatus = (data) => {
    setOrderStatus(data);
  };

  const updateJobStatus = (job_id) => {
    if (isEmpty(OrderStatus)) {
      return Toastr.warning("Please select an Order status.");
    }

    var _OrderStatus = OrderStatus;

    const query = {
      fulfillment_status: OrderStatus,
    };

    const callback = (response) => {
      if (response.fulfillment_status) {
        setToggleUpdate(false);

        setOrderStatus("");
        setTimeout(() => {
          setOrderStatus(_OrderStatus);
          return Toastr.success(`Order status updated successfully.`);
        }, 1000);
      }
    };
    const onError = (error) => {
      if (error.response) {
        if (error.response.data) {
          setOrderStatus(_OrderStatus);
          return Toastr.error(`${error.response.data.message}`);
        }
      }
    };
    UPDATE_SINGLE_JOB_STATUS(job_id, query, callback, onError);
  };

  const controlStatus = () => {
    switch (OrderStatus) {
      case "awaiting-processing":
        return (
          <>
            <option value="awaiting-processing">Awaiting-processing</option>
            <option value="processing">Processing</option>
            <option value="ready-to-ship">Ready to ship</option>
            <option value="shipped">Shipped</option>
          </>
        );
      case "processing":
        return (
          <>
            <option value="ready-to-ship">Ready to ship</option>
            <option value="shipped">Shipped</option>
          </>
        );

      case "ready-to-ship":
        return (
          <>
            <option value="shipped">Shipped</option>
          </>
        );

      case "shipped":
        return (
          <>
            <option value="shipped">Shipped</option>
          </>
        );

      default:
        break;
    }
  };

  const printTicket = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_CORE_API_URL}/print-jobs/${id}/ticket.pdf`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${job !== null ? job.order_item.product.name : "sample"}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoading(false);
      });
  };

  return (
    <>
      <div className={`card card-custom border-0  gutter-b rounded-none`}>
        <div className="card-header pt-2">
          <h3 className="card-title align-items-start flex-col">
            <button className="flex bg-gray-300 hover:bg-gray-500 text-gray  py-2 px-4 rounded">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/back.svg")}
              ></SVG>

              <span
                className="font-normal mt-1"
                onClick={() => history.goBack()}
              >
                Back
              </span>
            </button>
          </h3>
        </div>
      </div>

      <div className="container">
        <div className={`card card-custom gutter-b`}>
          {/*  */}
          <div className="card-body pt-2 pb-20">
            <div className="card-title py-5">
              {!toggleUpdate ? (
                <h5>
                  Job Detail:
                  {job !== null ? ` ${job.order_item.number}` : ""}
                </h5>
              ) : (
                <h5>Update Job Status</h5>
              )}
            </div>
            {/*  */}
            {!toggleUpdate ? (
              <div
                className="flex flex-column border-1 rounded pb-10"
                style={{
                  border: "1px solid rgba(56, 74, 98, 0.1)",
                }}
              >
                {/*  */}
                <div className="flex flex-col lg:flex-row">
                  <div className="flex px-4 py-2 m-2 ">
                    <img
                      className="h-20 align-self-top"
                      src={toS3Url(
                        `${
                          job !== null
                            ? job.order_item.product.thumbnail_path
                            : ""
                        }`
                      )}
                      alt={job !== null ? job.order_item.product.name : ""}
                    />
                    <div className="flex flex-column px-4">
                      <p className="font-bold">
                        {job !== null ? job.order_item.product.name : ""}
                      </p>
                      <span>
                        <b> Quantity: </b>
                        {job !== null ? job.order_item.quantity : ""}
                        &nbsp; Pieces
                      </span>
                      {job?.is_reprint_for ? (
                        <div className="reprint__tag">Reprint</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="flex flex-column px-4 py-2 m-2">
                    <p className="font-bold">
                      &#8358;{" "}
                      {job !== null
                        ? numeral(job.order_item.price).format("0,0")
                        : ""}
                    </p>
                    <span>
                      {" "}
                      <b>Deadline: </b> {formatDate(job?.created)}
                    </span>
                  </div>
                  <div className="flex flex-column px-4 py-2 m-2">
                    <button
                      className="bg-gray-100 hover:bg-gray-100 text-gray font-bold py-3 px-4 rounded"
                      onClick={toggleUpdateStatus}
                    >
                      Update Job Status
                    </button>
                    <button
                      className="bg-gray-100 hover:bg-gray-100 text-gray font-bold py-3 my-5 px-4 rounded"
                      onClick={printTicket}
                      disabled={loading}
                    >
                      Download Ticket
                      {loading && (
                        <span className="mr-1 spinner spinner-gray"></span>
                      )}
                    </button>
                  </div>
                </div>
                {/*  */}

                {/*  */}
                <div className="flex flex-col lg:flex-row">
                  <div className="flex px-4 py-2 m-2 ">
                    <div className="flex flex-column margin__20">
                      <p className="font-bold">Job Details</p>
                      <span>
                        Job Status:{" "}
                        {OrderStatus !== null ? ` ${OrderStatus}` : ""}
                      </span>
                      <span className="my-2">
                        Job Id:
                        {job !== null ? ` ${job.order_item.number}` : ""}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-column px-4 py-2 m-2">
                    <p className="font-bold">Specifications</p>
                    {propertyKeys &&
                      propertyKeys.map((key) => (
                        <div className="w-full text-sm flex my-2" key={key}>
                          <span className="font-bold">{key}:</span>
                          <span className="text-grey-darkest mx-2">
                            {orderItem.is_tshirt
                              ? orderItem.add_ons[key].map(
                                  (size) => `${size.name}(${size.quantity}) `
                                )
                              : orderItem.add_ons[key] &&
                                orderItem.add_ons[key].length > 0 &&
                                orderItem.add_ons[key][0].name}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>

                {job ? (
                  job.comments ? (
                    <span className="text-grey-700 font-bold block mt-2 ml-30">
                      Comments:{" "}
                      <span className="text-grey-700 font-light">
                        {job.comments}
                      </span>
                    </span>
                  ) : (
                    ""
                  )
                ) : null}

                <hr
                  className="bg-gray-300"
                  style={{
                    margin: "1rem 3rem",
                    border: "1px dashbed rgba(56, 74, 98, 0.1)",
                  }}
                />
                {/*  */}

                <p className="font-bold px-4 py-2 m-2">Print Files</p>

                <div className="flex flex-col m-5 lg:w-50">
                  {files ? (
                    files.map((item, key) => {
                      return (
                        <div
                          className="flex flex-row justify-between px-4 py-2 m-2"
                          style={{
                            border: "1px solid rgba(56, 74, 98, 0.1)",
                          }}
                          key={item[key]}
                        >
                          <p className="font-bold ">File {key + 1}</p>

                          {orderItem.item_type === "cc" ? (
                            <a
                              href={toCCUrl(`/${item[1]}`)}
                              target={`_blank`}
                              className="bg-green-500 hover:bg-green-500  text-white font-bold p-5 mt-2 rounded"
                              download
                            >
                              Download Document
                            </a>
                          ) : (
                            <a
                              href={toS3Url(`/${item[1]}`)}
                              target={`_blank`}
                              className="bg-green-500 hover:bg-green-500  text-white font-bold p-5 mt-2 rounded"
                              download
                            >
                              Download Document
                            </a>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="flex flex-row  justify-between px-4 py-2 m-2"
                      style={{
                        border: "1px solid rgba(56, 74, 98, 0.1)",
                      }}
                    >
                      <p className="font-bold mt-8">Pdf File</p>

                      <a
                        href={toS3Url(
                          `/files/custom_uploads/${
                            job ? job.order_item.pdf_file_name : " "
                          }`
                        )}
                        target={`_blank`}
                        className="bg-green-500 hover:bg-green-500  text-white font-bold p-5 mt-2 rounded"
                        download
                      >
                        Download Document
                      </a>
                    </div>
                  )}
                </div>

                {/* flex-col */}
              </div>
            ) : null}
            {/*  */}

            {toggleUpdate ? (
              <div
                className="flex flex-col border-1 rounded"
                style={{
                  border: "1px solid rgba(56, 74, 98, 0.1)",
                }}
              >
                <div className="flex flex-col lg:flex-row">
                  <div className="flex px-4 py-2 m-2 ">
                    <img
                      className="h-20 align-self-top"
                      src={toS3Url(
                        `${
                          job !== null
                            ? job.order_item.product.thumbnail_path
                            : ""
                        }`
                      )}
                      alt={job !== null ? job.order_item.product.name : ""}
                    />
                    <div className="flex flex-column px-4">
                      <p className="font-bold">
                        {job !== null ? job.order_item.product.name : ""}
                      </p>
                      <span>
                        <b> Quantity: </b>
                        {job !== null ? job.order_item.quantity : ""}
                        &nbsp; Pieces
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col  px-4 py-2 m-2">
                    <p className="font-bold">Order Details</p>
                    <span>
                      Job Status: {OrderStatus !== null ? OrderStatus : ""}
                    </span>
                    <span>
                      Job ID: {job !== null ? job.order_item.number : ""}
                    </span>
                  </div>
                  <div className="flex flex-column px-4 py-2 m-2">
                    <p className="font-bold">Specifications</p>
                    {propertyKeys &&
                      propertyKeys.map((key) => (
                        <div className="w-full text-sm flex my-2" key={key}>
                          <span className="font-bold">{key}:</span>
                          <span className="text-grey-darkest mx-2">
                            {orderItem.is_tshirt
                              ? orderItem.add_ons[key].map(
                                  (size) => `${size.name}(${size.quantity}) `
                                )
                              : orderItem.add_ons[key] &&
                                orderItem.add_ons[key].length > 0 &&
                                orderItem.add_ons[key][0].name}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>

                <hr
                  className="bg-gray-300"
                  style={{
                    margin: "1rem 3rem",
                    border: "1px dashed rgba(56, 74, 98, 0.1)",
                  }}
                />

                <div className="flex flex-col">
                  <div className="flex flex-col px-4 py-2 m-2 lg-w-50">
                    <label>Select Status Update</label>
                    <select
                      className="select minimal"
                      defaultValue={OrderStatus}
                      onChange={(e) => setOrderStatus(e.target.value)}
                    >
                      {controlStatus()}
                    </select>

                    {OrderStatus === "ready-to-ship" ? (
                      <FileUpload
                        id={id}
                        toggle={toggleUpdateStatus}
                        status={OrderUpdateStatus}
                      />
                    ) : (
                      ""
                    )}

                    {OrderStatus !== "ready-to-ship" ? (
                      <div className="flex flex-row w-100 justify-end  py-3 mt-5">
                        <button
                          className="bg-green-500 hover:bg-green-500 text-white font-bold py-3 px-4 rounded"
                          onClick={() => updateJobStatus(job.id)}
                        >
                          Save Job Status
                        </button>
                        <button
                          className="bg-gray-100 hover:bg-gray-100 text-gray font-bold py-3 px-4 mx-5 rounded"
                          onClick={toggleUpdateStatus}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
