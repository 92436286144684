import axios from "axios";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const USER_REQUESTED = "USER_REQUESTED";
export const USER_LOADED = "USER_LOADED";

export const LOGIN_URL = process.env.REACT_APP_CORE_API_URL;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export const actions = {
  login: (authToken) => ({ type: LOGIN, payload: { authToken } }),
  register: (authToken) => ({
    type: REGISTER,
    payload: { authToken },
  }),
  logout: () => ({ type: LOGOUT }),
  requestUser: (user) => ({ type: USER_REQUESTED, payload: { user } }),
  fulfillUser: (user) => ({ type: USER_LOADED, payload: { user } }),
};

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
