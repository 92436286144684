/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function RevenueWidget({ className }) {
  const weekIncome = "0";
  const monthIncome = "0";
  const annualIncome = "0";
  const totalIncome = "0";

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="px-8 pt-9 pb-3 flex-grow-1">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col text-left">
              <span className="text-grey-800 font-weight-bolder font-size-h3">
                Revenue
              </span>
            </div>
            <div className="flex flex-col text-right">
              <Link
                to="/revenue"
                className="text-grey-800 font-light text-lg flex items-center"
              >
                <span className="m-0">View all</span>
                <span className="svg-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/misc/next.svg")} />
                </span>
              </Link>
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body flex flex-col p-0">
          {/* begin::Chart */}
          {/* end::Chart */}

          {/* begin::Stats */}
          <div className="px-8 bg-white card-rounded flex-grow-1">
            {/* begin::Row */}
            <div className="flex justify-between flex-wrap m-0">
              <div className="pb-6">
                <div className="font-size-sm text-grey-400 font-medium">
                  This Week
                </div>
                <div className="font-size-h4 font-weight-bolder">
                  &#8358;{weekIncome}
                </div>
              </div>
              <div className="pb-6 text-right">
                <div className="font-size-sm text-grey-400 font-medium">
                  This Month
                </div>
                <div className="font-size-h4 font-weight-bolder">
                  &#8358;{monthIncome}
                </div>
              </div>
            </div>
            {/* end::Row */}
            {/* begin::Row */}
            <div className="flex justify-between flex-wrap m-0">
              <div className="pb-6">
                <div className="font-size-sm text-grey-400 font-medium">
                  This Year
                </div>
                <div className="font-size-h4 font-weight-bolder">
                  &#8358;{annualIncome}
                </div>
              </div>
              <div className="pb-6 text-right">
                <div className="font-size-sm text-grey-400 font-medium">
                  Total Revenue
                </div>
                <div className="font-size-h4 font-weight-bolder">
                  &#8358;{totalIncome}
                </div>
              </div>
            </div>
            {/* end::Row */}
          </div>
          {/* end::Stats */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}
