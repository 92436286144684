import React from 'react';
import { SingleJobWidget } from '../widgets';

export function SingleJobComponent(props) {
  return (
    <>
      <SingleJobWidget {...props} />
    </>
  );
}
