import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LOGIN, LOGOUT, REGISTER, USER_LOADED } from "../actions/Auth";

const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

const reducer = persistReducer(
  { storage, key: "v706-demo3-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case LOGIN: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }

      case REGISTER: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case LOGOUT: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case USER_LOADED: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export default reducer;
