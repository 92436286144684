export const jobs = [{
  name: "Custom Pink Coffee Mug",
  deadline: "Nov 12, 2020",
  price: "N250,000",
  amount: "200",
  product_id: "33949253",
  order_id: "33949253",
  address: {
    line1: "No. 40, Mara Close",
    line2: "Kakuri",
    state: "Kaduna",
    country: "Nigeria."
  }
},
{
  name: "Custom Pink Coffee Mug",
  deadline: "Nov 12, 2020",
  price: "N250,000",
  amount: "200",
  product_id: "33949253",
  order_id: "33949253",
  address: {
    line1: "No. 40, Mara Close",
    line2: "Kakuri",
    state: "Kaduna",
    country: "Nigeria."
  }
},
{
  name: "Custom Pink Coffee Mug",
  deadline: "Nov 12, 2020",
  price: "N250,000",
  amount: "200",
  product_id: "33949253",
  order_id: "33949253",
  address: {
    line1: "No. 40, Mara Close",
    line2: "Kakuri",
    state: "Kaduna",
    country: "Nigeria."
  }
},
{
  name: "Custom Pink Coffee Mug",
  deadline: "Nov 12, 2020",
  price: "N250,000",
  amount: "200",
  product_id: "33949253",
  order_id: "33949253",
  address: {
    line1: "No. 40, Mara Close",
    line2: "Kakuri",
    state: "Kaduna",
    country: "Nigeria."
  }
},
{
  name: "Custom Pink Coffee Mug",
  deadline: "Nov 12, 2020",
  price: "N250,000",
  amount: "200",
  product_id: "33949253",
  order_id: "33949253",
  address: {
    line1: "No. 40, Mara Close",
    line2: "Kakuri",
    state: "Kaduna",
    country: "Nigeria."
  }
}]