// import { createStore, applyMiddleware, compose } from "redux";

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import promiseMiddleware from "redux-promise";
import { reduxBatch } from "@manaflair/redux-batch";
import { persistStore } from "redux-persist";
import { createLogger } from "redux-logger";
import reducer from "../reducers";

const loggerMiddleware = createLogger();

let middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  promiseMiddleware,
  thunkMiddleware,
];

if (process.env.NODE_ENV !== "production") {
  middleware = [...middleware, loggerMiddleware];
}

const store = configureStore({
  reducer: reducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch],
});

// const store = createStore(
//   reducer,
//   compose(
//     applyMiddleware(...middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   )
// );

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

export default store;
