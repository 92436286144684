import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  USER_LOGIN,
  actions,
  GET_USER_SESSION,
} from "../../../../actions/Auth/index";

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();

      let data = {
        email: values.email,
        password: values.password,
      };

      setError(null);

      const callback = (response) => {
        if (response.token) {
          getUserSession(response.token);
          setSubmitting(false);
        }
      };
      const onError = (error) => {
        if (error.response) {
          disableLoading();
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
        }
      };

      USER_LOGIN(data, callback, onError);
    },
  });

  const getUserSession = (token = null) => {
    const callback = (response) => {
      if (response.user) {
        if (response.user.print_partners.length > 0) {
          props.login(token);
          props.fulfillUser(response.user);
        } else {
          setError("Sorry, you are not allowed to use this application.");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        disableLoading();
      }
    };
    const onError = (error) => {
      console.log(error);
    };

    GET_USER_SESSION(token, callback, onError);
  };

  return (
    <>
      <div className="loginScreen">
        <div className="loginScreen__body">
          <div className="login__card ">
            <Link to="/" className="flex-col-auto">
              <img
                alt="Logo"
                className="max-h-70px"
                src={toAbsoluteUrl("/media/svg/logos/printivo.svg")}
              />
            </Link>

            <img
              alt="Logo"
              className="mt-10"
              src={toAbsoluteUrl("/media/svg/logos/login-image.png")}
            />
          </div>
          <div className="login__card">
            
            <div
              className="login-form login-signin "
              id="kt_login_signin_form"
            >
              {/* begin::Head */}
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                  <FormattedMessage id="AUTH.LOGIN.TITLE" />
                </h3>
                <p className="text-grey-400 font-medium">
                  Enter your Email and Password
                </p>
              </div>
              {/* end::Head */}

              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                {formik.status ? (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-medium">
                      {formik.status}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {error != null ? (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-medium">{error}</div>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Email"
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                    )}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.password}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group flex flex-wrap justify-between items-center">
                  <Link
                    to="/auth/forgot-password"
                    className="text-dark-50 text-hover-primary my-3 mr-2"
                    id="kt_login_forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn btn-primary font-medium px-9 py-4 my-3`}
                  >
                    <span>Sign In</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </form>
              {/*end::Form*/}
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, actions)(Login));
