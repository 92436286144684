export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function isEmpty(val) {
  return val.trim().length === 0;
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const toS3Url = (pathname) =>
  process.env.REACT_APP_S3_BASE_URL + pathname;

export const toCCUrl = (pathname) =>
  process.env.REACT_APP_CC_BASE_URL + pathname;

export const formatDate = (dateString) => {
  Date.prototype.addDays = function(days) {
    var date = new Date(dateString);
    date.setDate(date.getDate() + days);
    return date;
  };

  var date = new Date(dateString).addDays(2, dateString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date).toLocaleDateString(undefined, options);
};
