/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_helpers';

import { jobs } from '../../../../mocks/jobs';
import { Link } from 'react-router-dom';

export function SearchPageWidget(props) {
  return (
    <>
      <div
        className={`card card-custom border-0  gutter-b rounded-none`}
      >
        <div className="card-header pt-5">
          <h3 className="card-title align-items-start flex-col">
            <span className="card-label font-weight-bolder text-dark">
              <b> Search Results for </b>
              {`“{search Parameter}”`}
            </span>
          </h3>
          <div className="card-toolbar">
            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
              <div
                className="relative"
                style={{ marginTop: '-20px' }}
              >
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                >
                  <option>This year | 2020</option>
                  <option>Missouri</option>
                  <option>Texas</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <SVG
                    src={toAbsoluteUrl(
                      '/media/svg/icons/general/down.svg',
                    )}
                  ></SVG>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={`card card-custom gutter-b`}>
          <div className="card-body pt-2 pb-20">
            <div className="table-responsive">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th className="p-0" style={{ width: '50px' }} />

                    <th
                      className="p-0"
                      style={{ minWidth: '70px' }}
                    />
                    <th
                      className="p-0"
                      style={{ minWidth: '150px' }}
                    />
                    <th
                      className="p-0"
                      style={{ minWidth: '150px' }}
                    />
                    <th
                      className="p-0"
                      style={{ minWidth: '150px' }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {jobs.map((item, i) => (
                    <tr
                      className="pl-5 pt-10 pb-10 m-9 border-gray-400 "
                      key={i + 1}
                    >
                      <td className="pl-5 pt-10 pb-10 ">
                        <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                          <span className="symbol-label">
                            <SVG
                              className="h-75 align-self-end"
                              src={toAbsoluteUrl(
                                '/media/svg/shapes/abstract-9.svg',
                              )}
                            ></SVG>
                          </span>
                        </div>
                      </td>
                      <td className="pl-5 pt-10 pb-10">
                        <br />
                        <Link
                          to={`/jobs/1`}
                          className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg py-5 mt-20"
                          style={{ fontSize: '16px' }}
                        >
                          {item.name}
                        </Link>

                        <span className="text-grey-700 font-normal block mt-2">
                          200 Pieces
                        </span>

                        <span className="text-grey-700 font-normal block mt-2">
                          Product ID: 3394923
                        </span>
                      </td>
                      <td className="text-left pt-10 pb-10">
                        <span
                          className="text-grey-700 font-bold block mt-8"
                          style={{ fontSize: '16px' }}
                        >
                          {item.price}
                        </span>
                        <span className="text-grey-700 font-normal block mt-2">
                          Deadline: 3 Days
                        </span>
                        <span className="text-red-700 font-normal block mt-2">
                          Order ID: 139392
                        </span>
                      </td>

                      <br />
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex p-20 content-center justify-items-center place-content-center">
                <button className="bg-gray-700 hover:bg-gray-700 text-white font-bold py-3 px-4 rounded">
                  Load More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
