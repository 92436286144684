import React from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";

export function AsideSearch() {
  return (
    <div className="fade w-full show">
      {/* begin::Form */}
      <div className="aside-logo">
        <SVG src={toAbsoluteUrl("/media/svg/logos/printivo.svg")} />
      </div>
      <form className="p-2 p-lg-3">
        <div className="flex">
          <div className="relative h-40px w-full">
            <input
              type="text"
              className="w-full pl-10 form-control form-control-lg form-control-solid h-40px"
              placeholder="Search..."
              id="generalSearch"
              autoComplete="false"
            />
            <span className="left-0 top-0 bottom-0 absolute flex items-center justify-center w-10">
              <span className="svg-icon svg-icon-lg">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                />
              </span>
            </span>
          </div>
        </div>
      </form>
      {/* end::Form */}

      {/* begin::List */}

      {/* begin::Item */}
      <NavLink to="/dashboard" className="p-3 mb-2" activeClassName="active">
        <div className="flex items-center text-grey-700 px-5">
          {/* begin::Symbol */}
          <div className="symbol symbol-40 symbol-light mr-4">
            <span className="symbol-label bg-hover-white">
              <span className="svg-icon h-50 align-self-center">
                <SVG src={toAbsoluteUrl("/media/svg/misc/006-dashboard.svg")} />
              </span>
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="flex flex-col flex-grow-1 mr-2">
            <span className="font-size-h6 mb-0">Dashboard</span>
          </div>
          {/* begin::End */}
        </div>
      </NavLink>
      {/* end::Item */}

      {/* begin::Item */}
      <NavLink to="/jobs" className="p-3 mb-2" activeClassName="active">
        <div className="flex items-center text-grey-700 px-5">
          {/* begin::Symbol */}
          <div className="symbol symbol-40 symbol-light mr-4">
            <span className="symbol-label bg-hover-white">
              <span className="svg-icon h-50 align-self-center">
                <SVG src={toAbsoluteUrl("/media/svg/misc/010-jobs.svg")} />
              </span>
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="flex flex-col flex-grow-1 mr-2">
            <span className="font-size-h6 mb-0">Jobs</span>
          </div>
          {/* begin::End */}
        </div>
      </NavLink>
      {/* end::Item */}

      {/* begin::Item */}
      <NavLink to="/revenue" className="p-3 mb-2" activeClassName="active">
        <div className="flex items-center text-grey-700 px-5">
          {/* begin::Symbol */}
          <div className="symbol symbol-40 symbol-light mr-4">
            <span className="symbol-label bg-hover-white">
              <span className="svg-icon h-50 align-self-center">
                <SVG src={toAbsoluteUrl("/media/svg/misc/014-revenue.svg")} />
              </span>
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="flex flex-col flex-grow-1 mr-2">
            <span className="font-size-h6 mb-0">Revenue</span>
          </div>
          {/* begin::End */}
        </div>
      </NavLink>
      {/* end::Item */}

      {/* begin::Item */}
      <NavLink
        to="/support"
        className="p-3 mb-2 absolute"
        style={{ bottom: "5rem" }}
        activeClassName="active"
      >
        <div className="flex items-center">
          {/* begin::Symbol */}
          <div className="symbol symbol-40 symbol-light mr-4">
            <span className="symbol-label bg-hover-white">
              <span className="svg-icon h-50 align-self-center">
                <SVG src={toAbsoluteUrl("/media/svg/misc/ticket.svg")} />
              </span>
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="flex flex-col flex-grow-1 mr-2">
            <span className="text-grey-800 font-size-h6 mb-0">
              Customer Support
            </span>
          </div>
          {/* begin::End */}
        </div>
      </NavLink>
      {/* end::Item */}

      {/* end::List */}
    </div>
  );
}
