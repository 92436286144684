import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { RevenuePage } from "./pages/RevenuePage";
import { SearchPage } from "./pages/SearchPage";
import { JobsPage } from "./pages/JobsPage";
import { SingleJobView } from "./pages/SingleJobView";
// import TicketPage from "./pages/TicketPage";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/jobs" exact={true} component={JobsPage} />
        <ContentRoute path="/jobs/:id" exact={true} component={SingleJobView} />
        <ContentRoute path="/revenue" component={RevenuePage} />
        <ContentRoute path="/search" exact={true} component={SearchPage} />
        {/* <ContentRoute path="/ticket" exact={true} component={TicketPage} /> */}
        <Redirect to="error" />
      </Switch>
    </Suspense>
  );
}
