/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { RevenueWidget, StatsChartWidget } from "../../widgets";

import { Link } from "react-router-dom";

export function RevenuePageWidget(props) {
  const incomeSeries = {
    name: "Net Profit",
    data: [0, 0, 0, 0, 0, 0, 0],
  };

  const jobsSeries = {
    name: "Jobs",
    data: [0, 0, 0, 0, 0, 0, 0],
  };

  const [jobs, setJobs] = useState(null);

  return (
    <>
      <div className={`card card-custom border-0  gutter-b rounded-none`}>
        <div className="card-header pt-5">
          <h3 className="card-title align-items-start flex-col">
            <span className="card-label font-weight-bolder text-dark">
              Revenue
            </span>
            <span className="text-grey-400 mt-3 font-medium font-size-sm">
              Track Revenue Across <span className="font-bold">Time</span>
            </span>
          </h3>
          <div className="card-toolbar">
            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
              <div className="relative" style={{ marginTop: "-20px" }}>
                <select className="select">
                  <option>This year | 2020</option>
                  <option>Missouri</option>
                  <option>Texas</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/general/down.svg")}
                  ></SVG>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="flex flex-col lg:flex-row mt-20">
          <div className="w-100 lg:w-1/3 xl:w-1/3 ">
            <RevenueWidget className="gutter-b card-stretch" />
          </div>
          <div className="w-100 lg:w-1/3 xl:w-1/3 "></div>
          <div className="w-100 lg:w-1/3 xl:w-1/3 "></div>
        </div>
        <div className="flex flex-col lg:flex-row mt-10 mb-10">
          <div className="w-100 lg:w-1/3 xl:w-1/3 ">
            <div className="relative">
              <select className="select" id="grid-state">
                <option>This Month | October</option>
                <option>Missouri</option>
                <option>Texas</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/general/down.svg")}
                ></SVG>
              </div>
            </div>
          </div>
          <div className="w-100 lg:w-1/3 xl:w-1/3 "></div>
          <div className="w-100 lg:w-1/3 xl:w-1/3 "></div>
        </div>

        <div className="flex flex-col lg:flex-row mt-10">
          <div className="w-100 lg:w-1/3 xl:w-1/3 mt-5">
            <StatsChartWidget
              symbolShape="circle"
              title="Weekly Income"
              amount="0"
              seriesObj={incomeSeries}
              valType="money"
              widgetID="income_chart"
              percentIncrease={0}
              baseColor="danger"
            />
          </div>
          <div className="w-100 lg:w-1/3 xl:w-1/3 mt-5 lg:mx-5 lg:m-0 lg:px-5">
            <StatsChartWidget
              className="gutter-b"
              title="Completed Jobs"
              seriesObj={jobsSeries}
              widgetID="jobs_chart"
              amount="0"
              percentIncrease={0}
              baseColor="info"
            />
          </div>
          <div className="w-100 lg:w-1/3 xl:w-1/3  "></div>
        </div>

        <div className={`card card-custom gutter-b`}>
          <div className="card-body pt-2 pb-20">
            <div className="card-title py-5">
              <h5 className="font-bold">
                Top {jobs ? jobs.length : 0} Most sold Products
              </h5>
            </div>

            {jobs ? (
              <div className="table-responsive">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th className="p-0" style={{ width: "50px" }} />
                      <th className="p-0" style={{ minWidth: "150px" }} />
                      <th className="p-0" style={{ minWidth: "120px" }} />
                      <th className="p-0" style={{ minWidth: "70px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {jobs.map((item, i) => (
                      <tr
                        className="pl-5 pt-10 pb-10 m-9 border-gray-400 "
                        key={i + 1}
                      >
                        <td className="pl-5 pt-10 pb-10 ">
                          <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                            <span className="symbol-label">
                              <SVG
                                className="h-75 align-self-end"
                                src={toAbsoluteUrl(
                                  "/media/svg/shapes/abstract-9.svg"
                                )}
                              ></SVG>
                            </span>
                          </div>
                        </td>
                        <td className="pl-5 pt-10 pb-10">
                          <br />
                          <Link
                            to={`/jobs/1`}
                            className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg py-5 mt-20"
                            style={{ fontSize: "16px" }}
                          >
                            {item.name}
                          </Link>
                        </td>
                        <td className="pt-10 pb-10">
                          <span className="text-grey-700 font-bold block mt-2">
                            Quantity Sold:
                          </span>
                          <span className="text-grey-700 font-light block mt-2">
                            {item.price}
                          </span>
                        </td>
                        <td className="text-right pt-10 pb-10">
                          <span className="text-grey-700 font-bold block mt-2">
                            Revenue Made:
                          </span>
                          <span className="text-grey-700 font-light block mt-2">
                            {item.price}
                          </span>
                        </td>

                        <br />
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="flex p-20 content-center justify-items-center place-content-center">
                  <button className="bg-gray-700 hover:bg-gray-700 text-white font-bold py-3 px-4 rounded">
                    Load More
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex content-center justify-items-center place-content-center">
                <div className="flex  bg-red-500 text-white w-50 p-5 font-bold">
                  You have no reveune data yet.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
